@material-lock: '\e897';
@material-question-mark: '\e8fd';
@material-warning-sign: '\e000';

@color-purple: #574785;
@color-teal: #0E8984;
@color-gray-1: #F8F8F8;

@screen-laptop: 992px;

